<template>
  <Card>
    <template #header> Albümler </template>
    <div class="row">
      <div class="col-6">
        <h3>Fotoğraflar</h3>
        <draggable
          v-model="list1"
          group="people"
          @change="confirmDelete($event, list1)"
        >
          <div
            class="list-group-item"
            v-for="(element, index) in list1"
            :key="element.id"
          >
            <img :src="element.img" alt="" class="img-fluid" />
          </div>
        </draggable>
      </div>

      <div class="col-6">
        <h3>Sil</h3>
        <draggable class="drag-container" v-model="list2" group="people">
        </draggable>
      </div>
    </div>
  </Card>
</template>
<style lang="scss" scoped>
.drag-container {
  min-height: 100px;
  border: 1px solid $dveb-primary;
  border-radius: 10px;
}
</style>
<script>
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,
  },
  data() {
    return {
      changes: [],
      list1: [
        {
          img: "/image/admin_logo.png",
          id: 1,
        },
        {
          img: "/image/admin_logo.png",
          id: 2,
        },
        {
          img: "/image/admin_logo.png",
          id: 3,
        },
        {
          img:  "/image/admin_logo.png",
          id: 4,
        },
      ],
      list2: [
        { name: "Juan", id: 5 },
        { name: "Edgard", id: 6 },
        { name: "Johnson", id: 7 },
      ],
    };
  },
  methods: {
    confirmDelete: function (event, list) {
      this.changes.push({
        event,
        list,
      });
      Swal.fire({
        title: "Fotoğraf silinecek, emin misin?",
        text: "Silinen fotoğraflar geri alınamaz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.changes = [];
          this.modal = false;
          Swal.fire("Silindi", "Fotoğraf başarıyla silindi", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Silinmedi", "İşlem başarıyla iptal edildi", "error");

          this.changes.forEach(({ event, list }) => {
            if (event.added) {
              let { newIndex } = event.added;
              list.splice(newIndex, 1);
            }

            if (event.removed) {
              let { oldIndex, element } = event.removed;
              list.splice(oldIndex, 0, element);
            }

            if (event.moved) {
              let { newIndex, oldIndex, element } = event.moved;
              list[newIndex] = list[oldIndex];
              list[oldIndex] = element;
            }
          });

          this.changes = [];
          this.modal = false;
        }
      });
    },
  },
};
</script>
